body {
  font-size: 12px;
}

.logo {
  width: 100%;
  height: 100%;
}

@media print {
  div.footer {
    position: fixed;
    bottom: 0;
    font-size: 8px;
  }
  .container {
    page-break-after: always;
    break-after: always;
  }
}
